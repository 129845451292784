import { AdminNavigationTabs } from "../AdminNavigationTabs.component"
import { Layout } from "../Layout/Layout.component"

export type AdminLayoutProps = {
	children: React.ReactNode
	origin: "create-workspace" | "enrichments" | "workspaces" | "linkedin-identities" | "data-manage" | "backend-manage"
	pageName: string
}

export function AdminLayout({ children, origin, pageName }: AdminLayoutProps) {
	return (
		<Layout pageName={pageName}>
			<div className="flex min-h-full flex-1 flex-col py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						BACK OFFICE
					</h2>
				</div>
				<div className="mt-2 sm:mx-auto sm:w-full sm:max-w-screen-xl">
					<AdminNavigationTabs origin={origin} />
					<div className="mt-8 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">{children}</div>
				</div>
			</div>
		</Layout>
	)
}
