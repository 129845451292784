import {
	type EnrichAccountResponse,
	enrichAccountResponseSchema,
	type GetLinkedinIdentitiesResponse,
	GetLinkedinIdentitiesResponseSchema,
	GetWorkspaceUsersTrainingStatisticsSchema,
	type IEnrichAccountParams,
	type IPlatformAdminGateway,
	type IRequestCallReprocessingParams,
	type LinkedinIdentity,
	type LinkedinIdentityCreateParams,
	LinkedinIdentityPropertiesSchema,
	type ListAllWorkspacesRouteResponse,
	ListAllWorkspacesRouteResponseSchema,
	type WorkspaceUsersTrainingStatistics,
} from "../../application/gateways/platform-admin.gateway"

export class HttpPlatformAdminGateway implements IPlatformAdminGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async requestCallReprocessing({ callId }: IRequestCallReprocessingParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/admin/request-call-reprocessing/${callId}`
		await fetch(endpointUrl, {
			method: "POST",

			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
	}

	public async enrichAccount({
		email,
		lastName,
		firstName,
	}: IEnrichAccountParams): Promise<EnrichAccountResponse | null> {
		const endpointUrl = `${this.baseApiUrl}/admin/enrichments/account-from-email`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify({
				email,
				firstName,
				lastName,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
		const json = await res.json()
		if (json === null) {
			return null
		}
		return enrichAccountResponseSchema.parse(json)
	}

	public async listWorkspaces(): Promise<ListAllWorkspacesRouteResponse> {
		const endpointUrl = `${this.baseApiUrl}/admin/workspaces`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		return ListAllWorkspacesRouteResponseSchema.parse(await res.json())
	}

	public async getWorkspaceUsersTrainingStatistics(workspaceId: string): Promise<WorkspaceUsersTrainingStatistics> {
		const endpointUrl = `${this.baseApiUrl}/admin/workspace/${workspaceId}/get-workspace-users-training-statistics`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		return GetWorkspaceUsersTrainingStatisticsSchema.parse(await res.json())
	}

	public async getLinkedinIdentities(): Promise<GetLinkedinIdentitiesResponse> {
		const endpointUrl = `${this.baseApiUrl}/admin/linkedin-identities`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		return GetLinkedinIdentitiesResponseSchema.parse(await res.json())
	}

	public async genericDataCallback(params: unknown): Promise<unknown> {
		const endpointUrl = `${this.baseApiUrl}/admin/data-generic`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
		const v = await res.json()
		return v
	}

	public async genericCallback(params: unknown): Promise<unknown> {
		const endpointUrl = `${this.baseApiUrl}/admin/generic`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
		const v = await res.json()
		return v
	}

	public async createIdentity(params: LinkedinIdentityCreateParams): Promise<LinkedinIdentity> {
		const endpointUrl = `${this.baseApiUrl}/admin/linkedin-identities`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
		return LinkedinIdentityPropertiesSchema.parse(await res.json())
	}

	public async deleteIdentity(id: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/admin/linkedin-identities/${id}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}
}
