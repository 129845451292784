import React from "react"
import { toast } from "react-toastify"

import type { MeetingNotesPreviewResponse } from "../../../../../core/application/gateways/users.gateway/meeting-notes-template"
import type { Call } from "../../../../../core/domain/Call.entity"
import type { MeetingNotesTemplateWithSections } from "../../../../../core/domain/MeetingNotesTemplate.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Modal } from "../../../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { DocElementRenderer } from "../../../../components/shared/DocElementRenderer.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"

type PreviewTemplateModalProps = {
	meetingNoteTemplate: MeetingNotesTemplateWithSections
	onClose: () => void
}

export function PreviewTemplateModal({ meetingNoteTemplate, onClose }: PreviewTemplateModalProps) {
	const { t } = useLanguage()
	const [calls, setCalls] = React.useState<Call[] | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const { callsGateway, meetingNotesTemplatesGateway } = useDependencies()
	const [selectedCallId, setSelectedCallId] = React.useState<string>("")
	const [isPreviewLoading, setIsPreviewLoading] = React.useState(false)
	const [previewResponse, setPreviewResponse] = React.useState<MeetingNotesPreviewResponse | null>(null)

	React.useEffect(() => {
		async function fetchCalls() {
			setIsLoading(true)
			const { calls } = await callsGateway.list({
				filter: {
					type: "any",
				},
				pagination: {
					pageNumber: 1,
					pageSize: 10,
				},
				tagIds: [],
			})

			setCalls(
				calls
					.filter((call) => call.props.hasTranscription) // not ideal: we only fetch one page of calls, if they have no transcription, no call will appear in this list
					// older calls don't have the transcriptions.words field, required by rippletide-data
					.filter((call) => call.props.createdAt > new Date("2024-09-01")),
			)
			setIsLoading(false)
		}

		fetchCalls()
	}, [callsGateway])

	const handleCallChange = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedCallId(event.target.value)
	}, [])

	const handlePreview = React.useCallback(async () => {
		if (!selectedCallId) {
			return
		}

		try {
			setIsPreviewLoading(true)
			const res = await meetingNotesTemplatesGateway.previewMeetingNotesTemplate(
				meetingNoteTemplate.id,
				selectedCallId,
			)
			setPreviewResponse(res)
		} catch (e) {
			console.error(e)
			toast(t("An error occurred while trying to preview the template"), {
				type: "error",
			})
		} finally {
			setIsPreviewLoading(false)
		}
	}, [meetingNoteTemplate.id, meetingNotesTemplatesGateway, selectedCallId, t])

	return (
		<Modal open={true} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">
				{t("Preview meeting note template: {{name}}", { name: meetingNoteTemplate.title })}
			</p>

			<p className="text-sm text-navy-600 mb-6">
				{t(
					"Select a call from the dropdown below to preview how this template will look like when applied to a call.",
				)}
			</p>

			{isLoading || !calls ? (
				<div className="flex items-center justify-center text-gray-900">
					<SpinnerIcon className="w-6 h-6" />
				</div>
			) : (
				<div>
					<Input.Group>
						<Input.Label>
							{t("Call")}
							<Input.Select
								disabled={isPreviewLoading || isLoading}
								value={selectedCallId}
								onChange={handleCallChange}
							>
								<Input.SelectOption value="" className="text-gray-400" disabled>
									{t("Select a call")}
								</Input.SelectOption>
								{calls?.map((call) => (
									<Input.SelectOption key={call.props.id} value={call.props.id}>
										{call.props.name}
									</Input.SelectOption>
								))}
							</Input.Select>
						</Input.Label>
					</Input.Group>
					{previewResponse ? (
						<div className="mt-4 border-2 border-dashed border-gray-300 p-12">
							<DocElementRenderer docElement={previewResponse.meetingNotes} />
						</div>
					) : (
						<div className="mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
							<p className="text-gray-400">{t("The preview will be displayed here")}</p>
						</div>
					)}
				</div>
			)}

			<div className="flex justify-end mt-6">
				<Button
					eventName={"Preview template clicked"}
					eventProperties={{ templateId: meetingNoteTemplate.id }}
					disabled={isLoading || !selectedCallId || isPreviewLoading}
					onClick={handlePreview}
					variant="primary"
				>
					{isPreviewLoading ? <SpinnerIcon className="w-6 h-6" /> : t("Preview")}
				</Button>
			</div>
		</Modal>
	)
}
