export class IntSpan {
	start: number
	end: number

	constructor(start: number, end: number) {
		this.start = start
		this.end = end
	}

	interesect(s: IntSpan): IntSpan | null {
		const start = Math.max(s.start, this.start)
		const end = Math.min(s.end, this.end)
		if (end <= start) {
			return null
		} else {
			return new IntSpan(start, end)
		}
	}

	shift(v: number): IntSpan {
		return new IntSpan(this.start + v, this.end + v)
	}
}
