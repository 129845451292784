import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"

import { NEW_TRAINING_PROGRAM_ID } from "../../config"
import { DEMO_CALL_ID } from "../../core/domain/Call.entity"
import { useAuthentication } from "../contexts/authentication.context"
import { useWorkspaceUsers } from "../contexts/workspace-users.context"
import { inviteTokenQueryParam } from "../hooks/useInviteToken"
import { AcceptTermsPage } from "../pages/AcceptTerms/AcceptTerms.page"
import { ApplyCouponPage } from "../pages/ApplyCoupon/ApplyCoupon.page"
import { BackendManagePage } from "../pages/BackOffice/BackendManage/BackendManage.page"
import { DataManagePage } from "../pages/BackOffice/DataManage/DataManage.page"
import { LinkedinIdentitiesPage } from "../pages/BackOffice/LinkedinIdentities/LinkedinIdentities.page"
import { WorkspacesPage } from "../pages/BackOffice/Workspaces/Workspaces.page"
import { CallPage } from "../pages/Call/Call.page"
import { CallsPage } from "../pages/Calls/Calls.page"
import { DashboardPage } from "../pages/Dashboard/Dashboard.page"
import { DealsPage } from "../pages/Deals/Deals.page"
import { ForgotPasswordPage } from "../pages/ForgotPassword/ForgotPassword.page"
import { InsightsDashboard } from "../pages/InsightsDashboard/InsightsDashboard.page"
import { JoinPage } from "../pages/Join/Join.page"
import { COACHING_SESSIONS_FOLDER_ID } from "../pages/Library/components/CoachingSession.util"
import { LibraryPage } from "../pages/Library/Library.page"
import { LoginPage } from "../pages/Login/Login.page"
import { HighImpactObjectionsPage } from "../pages/ObjectionReports/HighImpactObjections.page"
import { OnboardingPage } from "../pages/Onboarding/onboarding.page"
import { PublicBriefFormPage } from "../pages/PublicBriefForm/PublicBriefForm.page"
import { PublicSharedCalendarEvent } from "../pages/PublicSharedCalendarEvent/PublicSharedCalendarEvent.page"
import { PublicSharedCall } from "../pages/PublicSharedCall/PublicSharedCall.page"
import { PurchasePlanRedirectPage } from "../pages/PurchasePlanRedirect/PurchasePlanRedirect.page"
import { ResetPasswordPage } from "../pages/ResetPassword/ResetPassword.page"
import { SalesMotionPage } from "../pages/SalesMotion/SalesMotion.page"
import { makeWorkspaceSettingsPath } from "../pages/Settings/config"
import { SettingsPage } from "../pages/Settings/Settings.page"
import { SignupPage } from "../pages/Signup/Signup.page"
import { EditTrainingProgramPage } from "../pages/Training/Programs/EditTrainingProgram.page"
import type { EditTrainingProgramsURLParams } from "../pages/Training/Programs/EditTrainingProgram/params"
import { TrainingProgramsPage } from "../pages/Training/Programs/TrainingPrograms.page"
import { AuthenticatedRoute } from "./AuthenticatedRoute"
import { makeRedirectUrl } from "./makeRedirectUrl"
import { PublicRoute } from "./PublicRoute"

export const appPaths = {
	publicCalls: "/share/calls/:accessTokenOrJson/:sidePanelTab?",
	publicCalendarEvents: "/share/calendar-events/:calendarEventId",
	join: "/join",
	login: "/login",
	signup: "/signup",
	forgotPassword: "/forgot-password",
	resetPassword: "/reset-password",
	call: "/calls/:callId/:sidePanelTab?",
	recentCalls: "/calls",
	dashboard: "/dashboard",
	settings: `/settings/:settingsScope/:settingsType/:action?`,
	library: "/training/library/:libraryFolderId?/:callHighlightId?",
	libraryCoachingSessions: `/training/library/${COACHING_SESSIONS_FOLDER_ID}`,
	applyCoupon: "/apply-coupon/:couponId",
	adminWorkspacesList: "/admin/workspaces",
	adminLinkedinIdentities: "/admin/linkedin-identities",
	adminDataManage: "/admin/data-manage",
	adminBackendManage: "/admin/backend-manage",
	adminCreateWorkspaceWithOwner: "/admin/create-workspace",
	adminEnrichments: "/admin/enrichments",
	calendarRedirect: "/redirect/calendar-connected",
	billingRedirect: "/redirect/billing",
	planUpdateSuccessRedirect: "/redirect/plan-update-success",
	planPurchaseRedirect: "/purchase",
	resetPasswordRedirect: "/redirect/reset-password",
	analyticsDashboard: "/training/analytics",
	deals: "/deals/:viewType?",
	salesMotion: "/sales-motion/:viewType?",
	trainingPrograms: "/training/programs",
	myTrainingPrograms: "/training/my-programs/:programId?",
	editTrainingProgram: "/training/programs/:programId/edit/:stepName?",
	onboarding: "/onboarding/:type/:step?",
	acceptTerms: "/accept-terms",
	objectionReports: "/objection-reports",
	publicCreateBrief: "/public/create-brief",
} as const

export function Router() {
	const { authenticationState } = useAuthentication()
	const { workspaceUsers } = useWorkspaceUsers()
	const [searchParams] = useSearchParams()

	if (authenticationState === "loading") {
		return null
	}

	let rootPath: string
	if (authenticationState === "logged out") {
		rootPath = makeLoginPath()
	} else {
		rootPath = authenticationState.user.isWorkspaceManager() ? makeRecentCallsPath() : makeDashboardPath()
	}

	const userIsWorkspaceManager = authenticationState !== "logged out" && authenticationState.user.isWorkspaceManager()

	/**
	 * Workspaces created AFTER the ToS update must accept the ToS on signup.
	 * For existing workspaces, the workspace owner must accept the ToS when they have more than 5 users.
	 */
	const mustAcceptTerms =
		authenticationState !== "logged out" &&
		authenticationState.workspace.mustAcceptTerms(workspaceUsers) &&
		authenticationState.user.isWorkspaceOwner() &&
		!authenticationState.user.email.includes("dbegue") && // For Intescia only, we want Anna to accept the terms
		(authenticationState.workspace.isRippletideWorkspace() || !authenticationState.user.isRippletideEmployee())

	if (mustAcceptTerms) {
		return (
			<Routes>
				<Route path="*" element={<Navigate to={appPaths.acceptTerms} />} />
				<Route key="acceptTerms" path={appPaths.acceptTerms} element={<AcceptTermsPage />} />
			</Routes>
		)
	}

	return (
		<Routes>
			<Route path="*" element={<Navigate to={rootPath} />} />
			<Route
				key="publicCalls"
				path={appPaths.publicCalls}
				element={
					<PublicRoute>
						<PublicSharedCall />
					</PublicRoute>
				}
			/>
			<Route
				key="publicCalendarEvents"
				path={appPaths.publicCalendarEvents}
				element={
					<PublicRoute>
						<PublicSharedCalendarEvent />
					</PublicRoute>
				}
			/>
			<Route
				key="publicCreateBrief"
				path={appPaths.publicCreateBrief}
				element={
					<PublicRoute>
						<PublicBriefFormPage />
					</PublicRoute>
				}
			/>
			<Route
				key="join"
				path={appPaths.join}
				element={
					<PublicRoute>
						<JoinPage />
					</PublicRoute>
				}
			/>
			<Route
				key="resetPassword"
				path={appPaths.resetPassword}
				element={
					<PublicRoute>
						<ResetPasswordPage />
					</PublicRoute>
				}
			/>
			<Route
				key="signup"
				path={appPaths.signup}
				element={
					<PublicRoute
						makeLoggedInPath={(searchParams) => makeRedirectUrl(searchParams.get("redirectTo")) ?? rootPath}
					>
						<SignupPage />
					</PublicRoute>
				}
			/>
			<Route
				key="login"
				path={appPaths.login}
				element={
					<PublicRoute
						makeLoggedInPath={(searchParams) => makeRedirectUrl(searchParams.get("redirectTo")) ?? rootPath}
					>
						<LoginPage />
					</PublicRoute>
				}
			/>
			<Route
				key="forgotPassword"
				path={appPaths.forgotPassword}
				element={
					<PublicRoute
						makeLoggedInPath={(searchParams) => makeRedirectUrl(searchParams.get("redirectTo")) ?? rootPath}
					>
						<ForgotPasswordPage />
					</PublicRoute>
				}
			/>
			{/**
			 * The call route is accessible:
			 * - publicly using a user's invite token: /calls/:callId/:sidePanelTab?inviteToken=:inviteToken
			 * - when logged in: /calls/:callId/:sidePanelTab?
			 */}
			<Route
				key="call"
				path={appPaths.call}
				element={
					<AuthenticatedRoute allowWithQueryParam={inviteTokenQueryParam}>
						<CallPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="recentCalls"
				path={appPaths.recentCalls}
				element={
					<AuthenticatedRoute>
						<CallsPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="objectionReports"
				path={appPaths.objectionReports}
				element={
					<AuthenticatedRoute>
						<HighImpactObjectionsPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="onboarding"
				path={appPaths.onboarding}
				element={
					<AuthenticatedRoute>
						<OnboardingPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="dashboard"
				path={appPaths.dashboard}
				element={
					<AuthenticatedRoute>
						<DashboardPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="settings"
				path={appPaths.settings}
				element={
					<AuthenticatedRoute>
						<SettingsPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="library"
				path={appPaths.library}
				element={
					<AuthenticatedRoute>
						<LibraryPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="insights"
				path={appPaths.analyticsDashboard}
				element={
					<AuthenticatedRoute>
						<InsightsDashboard />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="sales-motion"
				path={appPaths.salesMotion}
				element={
					<AuthenticatedRoute>
						<SalesMotionPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="deals"
				path={appPaths.deals}
				element={
					<AuthenticatedRoute>
						<DealsPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="usersList"
				path={appPaths.adminWorkspacesList}
				element={
					<AuthenticatedRoute isPlatformAdminRoute>
						<WorkspacesPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="linkedinIdentities"
				path={appPaths.adminLinkedinIdentities}
				element={
					<AuthenticatedRoute isPlatformAdminRoute>
						<LinkedinIdentitiesPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="adminDataManage"
				path={appPaths.adminDataManage}
				element={
					<AuthenticatedRoute isPlatformAdminRoute>
						<DataManagePage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="adminBackendManage"
				path={appPaths.adminBackendManage}
				element={
					<AuthenticatedRoute isPlatformAdminRoute>
						<BackendManagePage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="calendarRedirect"
				path={appPaths.calendarRedirect}
				element={
					<AuthenticatedRoute>
						<Navigate to={makeCalendarSuccessfullyConnectedPath()} />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="billingRedirect"
				path={appPaths.billingRedirect}
				element={<Navigate to={makeWorkspaceBillingPath(searchParams)} />}
			/>
			<Route
				path={appPaths.planUpdateSuccessRedirect}
				element={<Navigate to={makeWorkspaceBillingPlanUpdateSuccessPath()} />}
			/>
			<Route
				key="planPurchaseRedirect"
				path={appPaths.planPurchaseRedirect}
				element={<PurchasePlanRedirectPage />}
			/>
			<Route
				key="resetPasswordRedirect"
				path={appPaths.resetPasswordRedirect}
				element={<Navigate to={makeResetPasswordPath(searchParams)} />}
			/>
			<Route
				key="applyCoupon"
				path={appPaths.applyCoupon}
				element={
					<AuthenticatedRoute>
						<ApplyCouponPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				key="myTrainingPrograms"
				path={appPaths.myTrainingPrograms}
				element={
					<AuthenticatedRoute>
						<TrainingProgramsPage view="my-training-programs" canManagePrograms={userIsWorkspaceManager} />
					</AuthenticatedRoute>
				}
			/>
			{userIsWorkspaceManager && (
				<Route
					key="trainingPrograms"
					path={appPaths.trainingPrograms}
					element={
						<AuthenticatedRoute>
							<TrainingProgramsPage view="training-programs" canManagePrograms={true} />
						</AuthenticatedRoute>
					}
				/>
			)}
			{userIsWorkspaceManager && (
				<Route
					key="editTrainingProgram"
					path={appPaths.editTrainingProgram}
					element={
						<AuthenticatedRoute>
							<EditTrainingProgramPage />
						</AuthenticatedRoute>
					}
				/>
			)}
		</Routes>
	)
}

function makeWorkspaceBillingPath(searchParams: URLSearchParams) {
	const search = new URLSearchParams(searchParams)
	return `${makeWorkspaceSettingsPath("billing")}${search.size ? `?${search.toString()}` : ""}`
}

export function makeResetPasswordPath(searchParams: URLSearchParams) {
	const search = new URLSearchParams(searchParams)
	return appPaths.resetPassword + (search.size ? `?${search.toString()}` : "")
}

function makeWorkspaceBillingPlanUpdateSuccessPath() {
	return `${makeWorkspaceSettingsPath("billing")}?success=plan-update`
}

export function makeAdminUsersListPath() {
	return appPaths.adminWorkspacesList
}

export function makeAdminLinkedinIdentitiesPath() {
	return appPaths.adminLinkedinIdentities
}

export function makeAdminDataManagePath() {
	return appPaths.adminDataManage
}

export function makeAdminBackendManagePath() {
	return appPaths.adminBackendManage
}

export function makeSignupPath({ redirectTo }: { redirectTo?: string } = {}) {
	if (redirectTo && redirectTo !== "/") {
		const searchParams = new URLSearchParams()
		searchParams.set("redirectTo", redirectTo)
		return `${appPaths.signup}?${searchParams.toString()}`
	}

	return appPaths.signup
}

export function makeLoginPath({ redirectTo }: { redirectTo?: string } = {}) {
	if (redirectTo && redirectTo !== "/") {
		const searchParams = new URLSearchParams()
		searchParams.set("redirectTo", redirectTo)
		return `${appPaths.login}?${searchParams.toString()}`
	}

	return appPaths.login
}

export function makeRecentCallsPath() {
	return appPaths.recentCalls
}

export function makeLibraryCoachingSessionsPath() {
	return appPaths.libraryCoachingSessions
}

export type CallPathParams = {
	sidePanelTab?: string
}

export type CallSearchParams = {
	startVideoAtSeconds?: number
	inviteToken?: string
}

export type DealPathParams = {
	viewType?: "board" | "list"
}

export type SalesMotionPathParams = {
	viewType?: "forecast" | "pipeline"
}

export type OnboardingStep = "account-setup" | "calendar-sync" | "crm-integration" | "invite-team" | "ready"

export type OnboardingType = "user" | "workspace"

export type OnboardingPathParams = {
	step?: OnboardingStep
	type: OnboardingType
}

export function makeOnboardingPath(onboardingType: OnboardingType, step?: OnboardingStep) {
	return step ? `/onboarding/${onboardingType}/${step}` : `/onboarding/${onboardingType}`
}

export function makeUserOnboardingPath(step?: OnboardingStep) {
	return makeOnboardingPath("user", step)
}

export function makeWorkspaceOnboardingPath(step?: OnboardingStep) {
	return makeOnboardingPath("workspace", step)
}

export function makeForgotPasswordPath(email?: string) {
	if (email) {
		const searchParams = new URLSearchParams()
		searchParams.set("email", email)
		return `${appPaths.forgotPassword}?${searchParams.toString()}`
	}

	return appPaths.forgotPassword
}

function makeCallSearch(search: CallSearchParams | undefined) {
	const searchParams = new URLSearchParams()
	if (search?.inviteToken) {
		searchParams.set("inviteToken", search.inviteToken)
	}
	if (search?.startVideoAtSeconds) {
		searchParams.set("startVideoAtSeconds", search.startVideoAtSeconds.toString())
	}

	return searchParams
}

export function makeCallPath(callId: string, params: CallPathParams | undefined, search?: CallSearchParams) {
	const searchParams = makeCallSearch(search)
	return `/calls/${callId}${params?.sidePanelTab ? `/${params?.sidePanelTab}` : ""}?${searchParams.toString()}`
}

export function makeDemoCallPath() {
	return makeCallPath(DEMO_CALL_ID, undefined)
}

export function makePublicSharedCallPath(
	publicAccessToken: string,
	params?: CallPathParams,
	search?: CallSearchParams,
) {
	const searchParams = makeCallSearch(search)
	return `/share/calls/${publicAccessToken}${params?.sidePanelTab ? `/${params?.sidePanelTab}` : ""}${
		searchParams.size ? `?${searchParams.toString()}` : ""
	}`
}

export function makeDashboardPath() {
	return appPaths.dashboard
}

export function makeDealsPath(params?: DealPathParams) {
	return `/deals${params?.viewType ? `/${params.viewType}` : ""}`
}

export function makeSalesMotionPath(viewType?: SalesMotionPathParams["viewType"]) {
	return `/sales-motion${viewType ? `/${viewType}` : ""}`
}

export function makeForecastPath() {
	return makeSalesMotionPath("forecast")
}

export function makeSalesMotionPipelinePath() {
	return makeSalesMotionPath("pipeline")
}

export function makeCalendarEventBriefPath(calendarEventId: string) {
	const searchParams = new URLSearchParams()
	searchParams.set("openBriefOfEventId", calendarEventId)

	return `${appPaths.dashboard}?${searchParams.toString()}`
}

function makeCalendarSuccessfullyConnectedPath() {
	const searchParams = new URLSearchParams()
	searchParams.set("calendarConnected", "true")

	return `${appPaths.dashboard}?${searchParams.toString()}`
}

export function makeTrainingProgramsPath() {
	return appPaths.trainingPrograms
}

export function makeMyTrainingProgramsPath(programId?: string) {
	if (programId) {
		return `/training/my-programs/${programId}`
	}

	return "/training/my-programs"
}

export function makeMyTrainingProgramPath(programId: string) {
	return `/training/my-programs/${programId}`
}

export function makeCreateTrainingProgramPath() {
	return `/training/programs/${NEW_TRAINING_PROGRAM_ID}/edit/name`
}

export function makeEditTrainingProgramPath(programId: string, stepName: EditTrainingProgramsURLParams["stepName"]) {
	return `/training/programs/${programId}/edit/${stepName}`
}

export function makeLibraryPath() {
	return "/training/library"
}

export function makeLibraryFolderPath(folderId: string) {
	return `/training/library/${folderId}`
}

export function makeLibraryFolderWithCallHighlightPath(folderId: string, callHighlightId: string) {
	return `/training/library/${folderId}/${callHighlightId}`
}

export function makeAnalyticsDashboardPath() {
	return appPaths.analyticsDashboard
}

export function makeCreateMeetingNotesTemplatePath() {
	return makeWorkspaceSettingsPath("templates", "create")
}

export function makeMeetingNotesTemplatePath(templateId: string) {
	return makeWorkspaceSettingsPath("templates", templateId)
}

export function makeMeetingNotesTemplatesPath() {
	return makeWorkspaceSettingsPath("templates")
}

export function makeObjectionReportsPath() {
	return appPaths.objectionReports
}

export function makeClaimUrl(claimToken: string) {
	const urlParams = new URLSearchParams()
	urlParams.set(inviteTokenQueryParam, claimToken)
	return `/join?${urlParams.toString()}`
}
