import { PlayIcon } from "@heroicons/react/24/outline"
import { t } from "i18next"
import React, { useContext, useEffect, useState } from "react"
import Markdown from "react-markdown"

import { CacheManager } from "../../../../core/application/cacheManager"
import { CallVideoPlayerContext } from "../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { makeCallPath } from "../../../router/Router"
import { IntSpan } from "../../../utils/iterutils/iterutils"
import {
	type DisplayedCall,
	type DisplayedTurn,
	type IDisplayedTurnSegment,
	quoteDisplayFromCall,
} from "../../../utils/salesmachine/representations"
import type { DocElement } from "../../../utils/taggedDoc"
import { Button } from "../../design-system/Button.component"
import { Duration } from "../../design-system/Duration.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingLink } from "../../design-system/TrackingLink.component"
import { MarkdownAnchorRenderer } from "../MarkdownLinkRenderer.component"

type DocElementRendererProps = {
	docElement: DocElement
	isRoot?: boolean
}

type TextStatement = {
	id: string
	text: string
}

type FreeTextJustification = {
	type: "free_text"
	text: string
}

type CallExplicitQuote = {
	type: "call_quote"
	text: string
	callId: string
	startsAtMs: number
	endsAtMs: number
}
function TurnDisplay({ turn, attendeeLabel }: { turn: DisplayedTurn; attendeeLabel: string }) {
	const segments = turn.segments.map((segment: IDisplayedTurnSegment, index: number) => {
		if (segment.type === "text") {
			return <span key={index}>{segment.text}</span>
		}
		if (segment.type === "skip") {
			return <span key={index}> [...] </span>
		}
		return null
	})

	return (
		<div className="mt-2 p-2 border-b">
			<div className="font-medium text-gray-900">{attendeeLabel}</div>
			<div>{segments}</div>
		</div>
	)
}

function CallExplicitQuoteComponent({ quote }: { quote: CallExplicitQuote }) {
	const videoPlayerContext = useContext(CallVideoPlayerContext)
	let sendPlayerToQuote = undefined
	if (videoPlayerContext && videoPlayerContext.call.props.id === quote.callId) {
		sendPlayerToQuote = () => {
			videoPlayerContext.seekPlayerTo(quote.startsAtMs / 1000, undefined, true)
		}
	}

	const { callsGateway } = useDependencies()
	const [displayableCall, setDisplayableCall] = useState<DisplayedCall | null>(null)

	useEffect(() => {
		const fetchCallData = async () => {
			const cached_call = await CacheManager.getInstance().getOrQuery(
				quote.callId,
				() => callsGateway.rawGet(quote.callId, undefined),
				(x) => x.props.transcription !== undefined,
			)
			const span = new IntSpan(quote.startsAtMs, quote.endsAtMs)
			const newDisplayableCall = quoteDisplayFromCall(cached_call, { quoted_text: quote.text, timespan: span })
			setDisplayableCall(newDisplayableCall)
		}

		fetchCallData()
	}, [quote.callId, callsGateway, quote.startsAtMs, quote.endsAtMs, quote.text])
	// Logic for "show more" functionality for quote text
	let quote_start = quote.startsAtMs / 1000 - 3
	if (quote_start < 0) {
		quote_start = 0
	}

	return (
		<div className="mt-2 rounded-r-md bg-gray-100 text-gray-800 px-2 py-2 text-sm border-l-2 border-gray-400">
			{/* Play Button */}
			<div className="text-sm font-medium text-gray-900 flex items-center">
				{sendPlayerToQuote ? (
					<TrackingButton
						onClick={sendPlayerToQuote}
						className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
						eventName="Seek video player to transcription quote"
						eventProperties={{
							seekPlayerToSeconds: quote.startsAtMs / 1000,
						}}
					>
						<PlayIcon className="w-4 h-4" />
						<span>
							<Duration className="cursor-pointer" seconds={quote.startsAtMs / 1000} /> -{" "}
							<Duration seconds={quote.endsAtMs / 1000} />
						</span>{" "}
						{/* Call name, modify as per available data */}
					</TrackingButton>
				) : (
					<TrackingLink
						to={makeCallPath(quote.callId, undefined, { startVideoAtSeconds: quote_start })}
						className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
						eventName="Transcription quote link to call clicked"
						eventProperties={{ callId: quote.callId }}
					>
						<PlayIcon className="w-4 h-4" />
						<span>{displayableCall?.name}</span> {/* Call name, modify as per available data */}
					</TrackingLink>
				)}
			</div>
			{/* Display Turns */}
			{displayableCall ? (
				displayableCall.displayedTurns.map((turn, index) => {
					// Extract attendee name (can be adjusted based on available data)
					const attendeeLabel = turn.attendeeLabel || "Unknown Attendee"
					return <TurnDisplay key={index} turn={turn} attendeeLabel={attendeeLabel} />
				})
			) : (
				<p>Loading turns...</p> // A simple loading state while data is fetched
			)}
		</div>
	)
}

function JustificationsDisplayComponent({ j_statement }: { j_statement: JustifiedStatement }) {
	const [showMoreExtracts, setShowMoreExtracts] = React.useState(false)
	const to_show_justifications = showMoreExtracts
		? j_statement.justifications
		: j_statement.justifications.slice(0, 3)
	return (
		<>
			{to_show_justifications.map((justification, i) => {
				if (justification.type === "call_quote") {
					return (
						<div key={i} className="mx-2 mt-2">
							<CallExplicitQuoteComponent quote={justification} />
						</div>
					)
				} else {
					return (
						<div key={i} className="mx-2 mt-2">
							Error
						</div>
					)
				}
			})}
			{j_statement.justifications.length && j_statement.justifications.length > 3 && (
				<div className="mt-2 flex items-center justify-center">
					<Button
						size="xs"
						variant="default"
						onClick={() => setShowMoreExtracts((prev) => !prev)}
						eventName="Show more extracts clicked"
					>
						{showMoreExtracts ? t("Show less") : t("Show more")}
					</Button>
				</div>
			)}
		</>
	)
}

type IJustification = FreeTextJustification | CallExplicitQuote

type JustifiedStatement = {
	statement: TextStatement
	justifications: Array<IJustification>
}

type AnnotationIdToStatementId = {
	annotation_id: string
	statement_id: string
}

class JustificationContext {
	annot_id_to_justified_statement: Record<string, JustifiedStatement>

	constructor(json_equivalent: Record<string, unknown>) {
		const ret: Record<string, JustifiedStatement> = {}
		const statement_id_to_statement: Record<string, JustifiedStatement> = {}
		const sttmts = json_equivalent["statements"] ?? null
		if (sttmts == null) {
			this.annot_id_to_justified_statement = {}
		} else {
			for (const v of (json_equivalent["statements"] as Record<string, JustifiedStatement[]>)[
				"accepted_statements"
			] as JustifiedStatement[]) {
				statement_id_to_statement[v.statement.id] = v
			}

			for (const v of json_equivalent["annotation_to_statement"] as AnnotationIdToStatementId[]) {
				const x = statement_id_to_statement[v.statement_id] ?? null
				if (x !== null) {
					ret[v.annotation_id] = x
				}
			}
			this.annot_id_to_justified_statement = ret
		}
	}
}

type InTextRender = {
	charLength(): number
	render(): JSX.Element
}

class RawTextRender implements InTextRender {
	text: string

	constructor(text: string) {
		this.text = text
	}

	charLength(): number {
		return this.text.length
	}

	render(): JSX.Element {
		const content = (
			<span className="prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500">
				<Markdown
					components={{
						a: MarkdownAnchorRenderer,
						p: "span",
					}}
				>
					{this.text}
				</Markdown>
			</span>
		)
		return content
	}
}

class WrapperTextRender implements InTextRender {
	inner_text: InTextRender
	wrapper: (arg: JSX.Element) => JSX.Element

	constructor(inner_text: InTextRender, wrapper: (arg: JSX.Element) => JSX.Element) {
		this.inner_text = inner_text
		this.wrapper = wrapper
	}

	render(): JSX.Element {
		return this.wrapper(this.inner_text.render())
	}

	charLength(): number {
		return this.inner_text.charLength()
	}
}

class CompositTextRenderer implements InTextRender {
	comp_list: Array<InTextRender>
	length: number

	constructor(comp_list: Array<InTextRender>) {
		this.comp_list = comp_list
		this.length = 0
		for (const v of this.comp_list) {
			this.length += v.charLength()
		}
	}

	render(): JSX.Element {
		const content = this.comp_list.map((element, index) => {
			return <React.Fragment key={index}>{element.render()}</React.Fragment>
		})
		return <React.Fragment>{content}</React.Fragment>
	}

	charLength(): number {
		return this.length
	}
}

class TextRenderUtils {
	static wrapBasedOnCharSpan(
		render: InTextRender,
		span: IntSpan,
		wrapper: (arg: JSX.Element) => JSX.Element,
	): InTextRender {
		if (render instanceof CompositTextRenderer) {
			const curr_index = 0
			const new_comp_list: InTextRender[] = []
			for (let i = 0; i < render.comp_list.length; i++) {
				const comp = render.comp_list[i]
				const next_index = curr_index + comp.charLength()
				const local_span = new IntSpan(curr_index, next_index)
				const intersection = local_span.interesect(span)
				if (intersection !== null) {
					const sub_wrapped = TextRenderUtils.wrapBasedOnCharSpan(
						comp,
						intersection.shift(-local_span.start),
						wrapper,
					)
					if (sub_wrapped instanceof CompositTextRenderer) {
						new_comp_list.push(...sub_wrapped.comp_list)
					} else {
						new_comp_list.push(sub_wrapped)
					}
				}
			}
			return new CompositTextRenderer(new_comp_list)
		} else if (render instanceof WrapperTextRender) {
			const new_inner = TextRenderUtils.wrapBasedOnCharSpan(render.inner_text, span, wrapper)
			return new WrapperTextRender(new_inner, render.wrapper)
		} else if (render instanceof RawTextRender) {
			const str = render.text
			const left = str.substring(0, span.start)
			const inside = str.substring(span.start, span.end)
			const right = str.substring(span.end)
			const new_comp_list: InTextRender[] = []
			if (left.length > 0) {
				new_comp_list.push(new RawTextRender(left))
			}
			new_comp_list.push(new WrapperTextRender(new RawTextRender(inside), wrapper))
			if (right.length > 0) {
				new_comp_list.push(new RawTextRender(right))
			}
			if (new_comp_list.length === 1) {
				return new_comp_list[0]
			} else {
				return new CompositTextRenderer(new_comp_list)
			}
		} else {
			throw new Error("Unknown txt render type (should not happen)")
		}
	}
}
type AnnotationWithId = {
	id: string
	span: IntSpan
}

type IDocelementRender = {
	render(): JSX.Element
}

type FloatingQuotesProps = {
	justifiedStatement: JustifiedStatement
}

export function FloatingQuotes({ justifiedStatement }: FloatingQuotesProps) {
	return (
		<div>
			<span className="hover:bg-gray-200 cursor-pointer">{justifiedStatement.statement.text}</span>
			<JustificationsDisplayComponent j_statement={justifiedStatement} />
		</div>
	)
}

class LeafDocElementRenderer implements IDocelementRender {
	justification_context: JustificationContext
	raw_text: string
	parent: DocElementListRender
	rendered: InTextRender

	constructor(
		parent: DocElementListRender,
		raw_text: string,
		metas: Record<string, unknown>,
		justification_context: JustificationContext,
	) {
		this.parent = parent
		this.raw_text = raw_text
		this.justification_context = justification_context
		this.rendered = this.parseAnnotationMetas(metas)
	}

	parseAnnotationMetas(metas: Record<string, unknown>): InTextRender {
		let current_render: InTextRender = new RawTextRender(this.raw_text)
		const possible_span = new IntSpan(0, current_render.charLength())
		const annotations = metas["annotations"] as Record<string, unknown> | undefined
		const justifications = annotations?.["justificationsv2"] as Array<AnnotationWithId> | undefined

		if (justifications) {
			for (const a of justifications) {
				const js = this.justification_context.annot_id_to_justified_statement[a.id] ?? null
				if (js !== null) {
					const corrected_span = possible_span.interesect(a.span)
					if (corrected_span !== null) {
						const WrapWithSpan = (arg: JSX.Element): JSX.Element => {
							const [isOpen, setIsOpen] = useState(false)

							const handleClick = () => {
								setIsOpen(!isOpen)
							}

							return (
								<>
									<span
										className={`cursor-pointer ${isOpen ? "bg-green-200" : "hover:bg-gray-200"}`}
										onClick={handleClick}
									>
										{arg}
									</span>
									{isOpen && <FloatingQuotes justifiedStatement={js} />}
								</>
							)
						}

						current_render = TextRenderUtils.wrapBasedOnCharSpan(
							current_render,
							possible_span,
							WrapWithSpan,
						)
					}
				}
			}
		}

		return current_render
	}

	parseAnnotationMetasOld(metas: Record<string, unknown>): InTextRender {
		let current_render: InTextRender = new RawTextRender(this.raw_text)
		const possible_span = new IntSpan(0, current_render.charLength())
		const annotations = metas["annotations"] as Record<string, unknown> | undefined
		const justifications = annotations?.["justificationsv2"] as Array<AnnotationWithId> | undefined
		if (justifications) {
			for (const a of justifications) {
				const js = this.justification_context.annot_id_to_justified_statement[a.id] ?? null
				if (js !== null) {
					const corrected_span = possible_span.interesect(a.span)
					if (corrected_span !== null) {
						const first_justification = js.justifications[0] as CallExplicitQuote
						const wrapWithSpan = (arg: JSX.Element): JSX.Element => {
							return <span title={first_justification.text}>{arg}</span>
						}
						current_render = TextRenderUtils.wrapBasedOnCharSpan(
							current_render,
							possible_span,
							wrapWithSpan,
						)
					}
				}
			}
		}

		return current_render
	}
	render(): JSX.Element {
		const v = (
			<span className="prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500">
				{this.rendered.render()}
			</span>
		)
		if (self.parent === null) {
			return <h1 className="text-md text-gray-600">{v}</h1>
		}

		return <li className="mt-2 text-sm text-gray-600">{v}</li>
	}
}

class DocElementListRender implements IDocelementRender {
	parent: DocElementListRender | null
	list_level: number
	name: string | null
	content: Array<IDocelementRender>

	constructor(
		parent: DocElementListRender | null,
		list_level: number,
		name: string | null,
		content: Array<IDocelementRender>,
	) {
		this.parent = parent
		this.list_level = list_level
		this.name = name
		this.content = content
	}

	render(): JSX.Element {
		const childrenElements = this.content.map((element, index) => {
			return <React.Fragment key={index}>{element.render()}</React.Fragment>
		})

		const Wrapper = ({ children }: React.PropsWithChildren) =>
			this.parent === null ? (
				<div className="gap-6 flex flex-col">{children}</div>
			) : (
				<ul className="list-disc list-inside">{children}</ul>
			)

		return (
			<div>
				{this.name !== null && (
					<>
						<h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{this.name}</h3>
					</>
				)}
				<Wrapper>{childrenElements}</Wrapper>
			</div>
		)
	}

	displayAbsolutePath() {
		console.log("Absolute Path:", "unavailable")
	}
}

class DocElementRenderUtils {
	static fullyRender(e: DocElement): JSX.Element {
		const renderer = DocElementRenderUtils.rootGetDocRenderer(e)
		return renderer.render()
	}

	static rootGetDocRenderer(e: DocElement): IDocelementRender {
		const jc = new JustificationContext(e.metas)
		return DocElementRenderUtils.recursiveGetDocRenderer(e, null, jc)
	}

	static recursiveGetDocRenderer(
		e: DocElement,
		parent: DocElementListRender | null,
		justification_context: JustificationContext,
	): IDocelementRender {
		let list_level = 0
		if (parent !== null) {
			list_level = parent.list_level
		}
		if (typeof e.content === "string") {
			return new LeafDocElementRenderer(parent as DocElementListRender, e.content, e.metas, justification_context)
		} else {
			let name: string | null = null
			if ("name" in e) {
				name = e["name"] as string
			}
			if (name === null) {
				list_level += 1
			}
			const list_render = new DocElementListRender(parent, list_level, name, [])
			const l_v = e.content as Array<DocElement>
			const result_list: Array<IDocelementRender> = []
			for (const x of l_v) {
				result_list.push(DocElementRenderUtils.recursiveGetDocRenderer(x, list_render, justification_context))
			}
			list_render.content = result_list
			return list_render
		}
	}
}

export function DocElementRenderBis({ docElement }: DocElementRendererProps) {
	return DocElementRenderUtils.fullyRender(docElement)
}
