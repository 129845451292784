import React from "react"
import { toast } from "react-toastify"

import { Button } from "../../../components/design-system/Button.component"
import { Input } from "../../../components/design-system/Input"
import { Modal } from "../../../components/design-system/Modal.component"
import { AdminLayout } from "../../../components/shared/AdminLayout/AdminLayout.component"
import { useDependencies } from "../../../contexts/dependencies.context"

// Define the expected response structure
type GenericDataCallbackResponse = {
	error?: string
}

export function DataManagePage() {
	const { platformAdminGateway } = useDependencies()
	const [isDisableCallModalOpen, setIsDisableCallModalOpen] = React.useState(false)
	const [callId, setCallId] = React.useState("")

	const handleDisableCallProcessing = React.useCallback(async () => {
		try {
			const response = (await platformAdminGateway.genericDataCallback({
				function: "prevent-processing",
				callId: callId,
			})) as GenericDataCallbackResponse

			if (response.error) {
				if (typeof response.error === "string") {
					toast(response.error, {
						type: "error",
					})
				} else {
					toast("some internal error occured", {
						type: "error",
					})
				}
			} else {
				toast("Call processing disabled", {
					type: "success",
				})
				setIsDisableCallModalOpen(false)
			}
		} catch (e) {
			toast("Failed to disable call processing", {
				type: "error",
			})
		}
	}, [callId, platformAdminGateway])

	const handleRetryJobsInError = React.useCallback(async () => {
		if (!window.confirm("Are you sure you want to retry jobs in error?")) {
			return
		}

		try {
			const response = (await platformAdminGateway.genericDataCallback({
				function: "retry-jobs-in-error",
			})) as GenericDataCallbackResponse

			if (response.error) {
				toast(response.error, {
					type: "error",
				})
			} else {
				toast("Jobs in error retried", {
					type: "success",
				})
			}
		} catch (e) {
			toast("Failed to retry jobs in error", {
				type: "error",
			})
		}
	}, [platformAdminGateway])

	return (
		<AdminLayout origin="data-manage" pageName="Back office: Data Management">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold text-gray-900">Data Management</h1>
						<p className="mt-2 text-sm text-gray-700">
							Manage various data operations for the back office.
						</p>
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<Button
							eventName="Disable call processing clicked"
							variant="primary"
							onClick={() => setIsDisableCallModalOpen(true)}
						>
							Disable Call Processing
						</Button>
						<Button
							eventName="Retry jobs in error clicked"
							variant="primary"
							className="ml-4"
							onClick={handleRetryJobsInError}
						>
							Retry Jobs In Error
						</Button>
					</div>
				</div>
			</div>

			<Modal open={isDisableCallModalOpen} onClose={() => setIsDisableCallModalOpen(false)}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						handleDisableCallProcessing()
					}}
				>
					<Input.Group>
						<Input.Label>Call ID</Input.Label>
						<Input.Input
							type="text"
							placeholder="Enter Call ID"
							value={callId}
							onChange={(e) => setCallId(e.target.value)}
						/>
					</Input.Group>
					<Button
						className="mt-4"
						eventName="Disable call processing submitted"
						variant="primary"
						type="submit"
					>
						Submit
					</Button>
				</form>
			</Modal>
		</AdminLayout>
	)
}
