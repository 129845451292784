import assertNever from "assert-never"
import _, { omit } from "lodash"
import { z } from "zod"

import type { TFunction, TranslationKey } from "../../app/contexts/language.context"
import type { TimelineItem } from "../../app/pages/Call/components/ProcessingTimeline/ProcessingTimeline.component"
import { makeWorkspaceBillingPath, makeWorkspaceSettingsPath } from "../../app/pages/Settings/config"
import { areAllPropertiesNullishOrEmpty } from "../../app/utils/object"
import { INTESCIA_WORKSPACE_ID } from "../../config"
import { RelationshipSchema } from "../application/gateways/relationships.gateway"
import { BaseCall, type BaseCallProperties, baseCallResponseSchema } from "./BaseCall.entity"
import { calendarEventBriefSchema } from "./CalendarEvent.entity/CalendarEventBrief"
import type { leadKnowledgeV1Schema, leadKnowledgeV2Contact, leadKnowledgeV2Schema } from "./LeadKnowledge.entity"
import { leadKnowledgeSchema } from "./LeadKnowledge.entity"
import { TagType, tagTypePropertiesSchema } from "./TagType.entity"
import type { Workspace } from "./Workspace.entity"

export const DEMO_CALL_ID = "demo"
export const DEMO_CALL_WORKSPACE_ID = "demo_workspace"
export const DEMO_CALL_NAME = "Introduction to Rippletide"

const metricSchema = z.object({
	label: z.string(),
	score: z.number(),
	maxScore: z.number(),
	rationale: z.string(),
	quotes: z.array(z.string()),
})

const strengthOrImprovementSchema = z.object({
	aspect: z.string(),
	suggestion: z.string(),
	/** Quote might be empty string if LLM found nothing to quote */
	quote: z.string(),
})

const scoringAnalysisSchema = z.object({
	metrics: z.array(metricSchema),
})

const coachingAnalysisMetadataSchema = z.object({
	generatedAt: z.coerce.date(),
	model: z.string(),
	promptName: z.string(),
	promptVersion: z.string(),
	promptSha: z.string(),
	comment: z.string().optional(),
})
const coachingAnalysisSchema = z
	.array(
		z.object({
			metadata: coachingAnalysisMetadataSchema.optional(),
			generalComments: z.string(),
			strengths: z.array(strengthOrImprovementSchema),
			areasForImprovement: z.array(strengthOrImprovementSchema),
		}),
	)
	.nonempty()

const frameworkNameSchema = z.enum(["MEDDIC", "SPICED", "BANT"])
export type FrameworkName = z.infer<typeof frameworkNameSchema>
const frameworkScoringSchema = z.object({
	framework: frameworkNameSchema,
	metrics: z.array(metricSchema),
})

export const accountSchema = z.object({
	crmId: z.string(),
	crmUrl: z.string(),
	name: z.string(),
	deal: z
		.object({
			amount: z.number().optional().nullable(),
			name: z.string().optional().nullable(),
			status: z.string().optional().nullable(),
			crmId: z.string().optional().nullable(),
			crmUrl: z.string().optional().nullable(),
			closeDate: z.string().optional().nullable(),
		})
		.optional()
		.nullable(),
})

export enum CallInvitationStatus {
	Invited = "Invited", // invited but did not join
	InvitedAndJoined = "InvitedAndJoined",
	Joined = "Joined", // joined but was not invited
}

const attendeesSchema = z.array(
	z.object({
		invitationStatus: z.nativeEnum(CallInvitationStatus),
		id: z.string(),
		providerAttendeeId: z.string().optional(),
		role: z.enum(["agent", "lead"]),
		email: z.string().optional(),
		modjoUserId: z.number().optional(),
		fullName: z.string().optional(),
		jobDepartment: z.string().optional(),
		crmUserId: z.string().optional(),
		talkRatio: z.number().optional(),
		providerParticipantId: z.string().optional(),
	}),
)

/** copied from @backend */
export enum HttpPresentedUnprocessableReasonCode {
	EmptyTranscription = "EmptyTranscription",
	LanguageNotSupported = "LanguageNotSupported",
}

/** copied from @backend */
export type HttpPresentedUnprocessableReason =
	| { code: HttpPresentedUnprocessableReasonCode.EmptyTranscription }
	| { code: HttpPresentedUnprocessableReasonCode.LanguageNotSupported; languageCode?: string | undefined }
	| null

const UnprocessableReasonParser_EmptyTranscription = z.object({
	code: z.literal(HttpPresentedUnprocessableReasonCode.EmptyTranscription),
})

const UnprocessableReasonParser_LanguageNotSupported = z.object({
	code: z.literal(HttpPresentedUnprocessableReasonCode.LanguageNotSupported),
	languageCode: z.string().optional(),
})

const crmSyncV2OpportunitySchema = z.object({
	crmEntityId: z.string(),
	status: z.enum(["created", "enriched"]),
	name: z.string(),
})

const crmSyncV2ParticipantSchema = z.object({
	email: z.string(),
	crmEntityType: z.enum(["contact"]),
	crmEntityId: z.string(),
	status: z.enum(["created", "enriched", "converted"]),
})

const crmSyncV2TaskSchema = z.object({
	crmEntityId: z.string(),
	title: z.string(),
	type: z.enum(["todo", "call"]),
})

const crmSyncV2AccountSchema = z.object({
	crmEntityId: z.string(),
	status: z.enum(["created", "enriched"]),
	name: z.string(),
})

const crmSyncSuccessV2Schema = z.object({
	isSuccess: z.literal(true),
	crmProvider: z.enum(["salesforce", "hubspot"]),
	instanceUrl: z.string(),
	syncedAt: z.coerce.date(),
	version: z.literal(2),
	opportunity: crmSyncV2OpportunitySchema,
	participants: z.array(crmSyncV2ParticipantSchema),
	tasks: z.array(crmSyncV2TaskSchema),
	account: crmSyncV2AccountSchema,
})

const crmSyncFailureV2Schema = z.object({
	isSuccess: z.literal(false),
	crmProvider: z.enum(["salesforce", "hubspot"]),
	instanceUrl: z.string(),
	syncedAt: z.coerce.date(),
	version: z.literal(2),
	opportunity: crmSyncV2OpportunitySchema.nullish(),
	participants: z.array(crmSyncV2ParticipantSchema).nullish(),
	tasks: z.array(crmSyncV2TaskSchema).nullish(),
	account: crmSyncV2AccountSchema.nullish(),
	errorName: z.string(),
})

const crmSyncSuccessV1Schema = z.object({
	isSuccess: z.literal(true),
	crmProvider: z.enum(["salesforce", "hubspot"]),
	instanceUrl: z.string(),
	syncedAt: z.coerce.date(),
	crmContactId: z.string(),
	crmAccountId: z.string(),
	crmFollowUpTaskId: z.string(),
	crmCallLogTaskId: z.string(),
	version: z.literal(1).nullish(),
})

const crmSyncFailureV1Schema = z.object({
	isSuccess: z.literal(false),
	crmProvider: z.enum(["salesforce", "hubspot"]),
	instanceUrl: z.string(),
	syncedAt: z.coerce.date(),
	errorName: z.string(),
	crmContactId: z.string().nullable().optional(),
	crmAccountId: z.string().nullable().optional(),
	crmFollowUpTaskId: z.string().nullable().optional(),
	crmCallLogTaskId: z.string().nullable().optional(),
	version: z.literal(1).nullish(),
})

const v1CrmSyncSchema = z.discriminatedUnion("isSuccess", [crmSyncSuccessV1Schema, crmSyncFailureV1Schema])

const v2CrmSyncSchema = z.discriminatedUnion("isSuccess", [crmSyncSuccessV2Schema, crmSyncFailureV2Schema])

export const crmSyncSchema = z.union([v1CrmSyncSchema, v2CrmSyncSchema]).nullish()

export const callObjectionSchema = z.object({
	title: z.string(),
	description: z.string(),
	extracts: z.array(
		z.object({
			startsAtMs: z.number(),
			endsAtMs: z.number(),
			quote: z.string().nullable(),
		}),
	),
	responseAnalysis: z.string(),
	recommendations: z.array(z.string()),
	riskLevel: z.enum(["low", "medium", "high"]),
})

export type CallObjection = z.infer<typeof callObjectionSchema>

export const ApiCallResponseSchema = z
	.object({
		scoringAnalysis: scoringAnalysisSchema.optional(),
		coachingAnalysis: coachingAnalysisSchema.optional(),
		frameworkScorings: z.array(frameworkScoringSchema).optional(),

		assignedUserId: z.string().optional(),
		leadKnowledge: leadKnowledgeSchema.optional(),
		publicAccessToken: z.string().optional(),
		hasAudio: z.boolean(),
		audioMimeType: z.string(),
		audioPath: z.string(),
		unprocessableReason: z
			.discriminatedUnion("code", [
				UnprocessableReasonParser_EmptyTranscription,
				UnprocessableReasonParser_LanguageNotSupported,
			])
			.optional()
			.nullable(),
		attendees: attendeesSchema,
		account: z.optional(accountSchema),
		briefing: calendarEventBriefSchema.optional(),
		crmSync: crmSyncSchema,
		relationship: RelationshipSchema.omit({ activities: true, contacts: true, users: true }).optional(),
		tags: z.array(tagTypePropertiesSchema).optional(),
		objections: z.array(callObjectionSchema).optional(),
		hasTranscription: z.boolean(),
	})
	.merge(baseCallResponseSchema)

export type ApiCall = z.infer<typeof ApiCallResponseSchema>
export type Metric = z.infer<typeof metricSchema>
export type StrengthOrImprovement = z.infer<typeof strengthOrImprovementSchema>
export type ScoringAnalysis = z.infer<typeof scoringAnalysisSchema>
export type CoachingAnalysis = z.infer<typeof coachingAnalysisSchema>
export type LeadKnowledge = z.infer<typeof leadKnowledgeSchema>
export type LeadKnowledgeV1 = z.infer<typeof leadKnowledgeV1Schema>
export type LeadKnowledgeV2 = z.infer<typeof leadKnowledgeV2Schema>
export type LeadKnowledgeV2Contact = z.infer<typeof leadKnowledgeV2Contact>

export type CallProperties = Omit<ApiCall, "createdAt" | "tags"> &
	BaseCallProperties & {
		audioFileUrl: string
		score: {
			totalScore: number
			totalMaxScore: number
			label: "Overall score" | "MEDDIC score"
		} | null
		scoringAnalysis: ScoringAnalysis | undefined
		coachingAnalysis: CoachingAnalysis | undefined
		unprocessableReason?: HttpPresentedUnprocessableReason
		tags?: TagType[]
	}

export class Call extends BaseCall<CallProperties> {
	constructor(_props: CallProperties) {
		super(_props)
	}

	public static fromApiCall(apiCall: unknown, apiUrl: string): Call {
		const parsedApiCall = ApiCallResponseSchema.safeParse(apiCall)
		if (!parsedApiCall.success) {
			console.error(parsedApiCall.error)
			throw new Error("Schema validation failed for Call.fromApiCall")
		}
		const call = parsedApiCall.data
		const createdAt = new Date(call.createdAt)
		const audioFileUrl = apiUrl + call.audioPath
		const videoFileUrl = call.video?.url

		const upperLanguage = call.language?.toUpperCase()
		// flag "EN" doesn't exist in the country flags, so we use "US" instead
		// all other languages are the same as the country code
		const countryCode = upperLanguage === "EN" ? "US" : upperLanguage

		return new Call({
			...call,
			createdAt,
			audioFileUrl,
			coachingAnalysis: call.coachingAnalysis,
			scoringAnalysis: call.scoringAnalysis,
			countryCode,
			videoFileUrl,
			language: "en",
			tags: call.tags?.map((tag) => TagType.fromProperties(tag)),
			score: ((): CallProperties["score"] | null => {
				if (call.workspaceId === INTESCIA_WORKSPACE_ID && call.scoringAnalysis) {
					return {
						totalScore: _(call.scoringAnalysis.metrics).sumBy("score"),
						totalMaxScore: _(call.scoringAnalysis.metrics).sumBy("maxScore"),
						label: "Overall score",
					}
				}

				const meddicScoring = call.frameworkScorings?.find((sc) => sc.framework === "MEDDIC")
				if (call.workspaceId !== INTESCIA_WORKSPACE_ID && meddicScoring) {
					return {
						totalScore: _(meddicScoring.metrics).sumBy("score"),
						totalMaxScore: _(meddicScoring.metrics).sumBy("maxScore"),
						label: "MEDDIC score",
					}
				}
				return null
			})(),
		})
	}

	public getCrmSyncStatus(
		t: TFunction,
		workspace: Workspace | undefined,
		teaseCrmSync: boolean,
		isWorkspaceOwner: boolean,
	): {
		status: "success" | "pending" | "error" | "neutral" | "warning" | null
		linkTo: string | undefined
		label: string | undefined
	} {
		const timelineItem = this.getCrmSyncTimelineItem({
			t,
			workspace,
			teaseCrmSync,
			isWorkspaceOwner,
		})
		return {
			status: timelineItem?.status ?? null,
			linkTo: timelineItem?.linkTo,
			label: timelineItem?.label,
		}
	}

	public formatToTimeline({
		t,
		workspace,
		teaseCrmSync,
		isWorkspaceOwner,
	}: {
		t: TFunction
		workspace: Workspace | undefined
		teaseCrmSync: boolean
		isWorkspaceOwner: boolean
	}): TimelineItem[] {
		const timeline: TimelineItem[] = [
			{
				label: t("Call recorded"),
				status: "success",
			},
			{
				label: t("Call transcribed"),
				status: this.makeTimelineStatus(this.props.transcription),
			},
			{
				label: t("Call insights extracted"),
				status: this.makeTimelineStatus(this.props.meetingNotes && this.props.leadKnowledge),
				children: [
					{
						label: t("Call summarized"),
						status: "success",
					},
					{
						label: t("Lead knowledge extracted"),
						status: "success",
					},
					{
						label: t("Next best action suggested"),
						status: "success",
					},
				],
			},
		]

		const crmSyncItem = this.getCrmSyncTimelineItem({
			t,
			workspace,
			teaseCrmSync,
			isWorkspaceOwner,
		})
		if (crmSyncItem) {
			timeline.push(crmSyncItem)
		}

		return timeline
	}

	public getCrmSyncTimelineItem({
		t,
		workspace,
		teaseCrmSync,
		isWorkspaceOwner,
	}: {
		t: TFunction
		workspace: Workspace | undefined
		teaseCrmSync: boolean
		isWorkspaceOwner: boolean
	}): TimelineItem | null {
		const hasCrmIntegrationSetup = workspace?.hasCrmIntegrationSetup
		const canUseCrmIntegration = workspace?.isPlanFeatureEnabled("crm-integration")

		if (!canUseCrmIntegration) {
			return {
				label: t("Upgrade to a Flex plan now to connect your CRM."),
				linkTo: makeWorkspaceBillingPath(),
				status: "neutral",
			}
		}

		if (teaseCrmSync) {
			return {
				label: t("Ready for CRM sync"),
				status: "neutral",
			}
		}

		if (!hasCrmIntegrationSetup) {
			if (isWorkspaceOwner) {
				return {
					label: t("Set up automated CRM sync"),
					linkTo: makeWorkspaceSettingsPath("crm"),
					status: "warning",
				}
			}

			return {
				label: t("No CRM sync enabled"),
				status: "warning",
				tooltip: t("CRM integration must be set up by your workspace owner"),
			}
		}

		if (this.isDemoCall()) {
			return {
				label: t("Call synced with CRM"),
				status: "success",
			}
		}

		if (!this.isProcessable) {
			return {
				label: t("CRM sync failed"),
				status: "error",
				children: [
					{
						label: t("Call is not processable."),
						status: "error",
					},
				],
			}
		}

		if (!this.props.assignedUserId) {
			return {
				label: t("CRM sync failed"),
				status: "error",
				children: [
					{
						label: t("Call is not assigned to a user."),
						status: "error",
					},
				],
			}
		}

		const crmSync = this._props.crmSync
		if (!crmSync) {
			return {
				label: t("CRM sync in progress"),
				status: "pending",
			}
		}

		if (crmSync.isSuccess) {
			const opportunityBaseUrl =
				crmSync.crmProvider === "hubspot" ? `${crmSync.instanceUrl}/deal` : crmSync.instanceUrl
			const participantBaseUrl =
				crmSync.crmProvider === "hubspot" ? `${crmSync.instanceUrl}/contact` : crmSync.instanceUrl
			const accountBaseUrl =
				crmSync.crmProvider === "hubspot" ? `${crmSync.instanceUrl}/company` : crmSync.instanceUrl
			if (crmSync.version === 2) {
				return {
					label: t("Call synced with CRM"),
					status: "success",
					children: [
						{
							label:
								crmSync.opportunity.status === "created"
									? t("Opportunity created")
									: t("Opportunity enriched"),
							status: "success",
							href: `${opportunityBaseUrl}/${crmSync.opportunity.crmEntityId}`,
						},
						{
							label: t("Participants enriched"),
							status: "success",
							children: crmSync.participants.map((participant) => ({
								label: participant.email,
								status: "neutral",
								href: `${participantBaseUrl}/${participant.crmEntityId}`,
							})),
						},
						{
							label: t("Follow-up tasks created"),
							status: "success",
						},
						{
							label: crmSync.account.status === "created" ? t("Account created") : t("Account enriched"),
							status: "success",
							href: `${accountBaseUrl}/${crmSync.account.crmEntityId}`,
						},
					],
				}
			}

			return {
				label: t("Call synced with CRM"),
				status: "success",
				children: [
					{
						label: t("Account enriched"),
						status: "neutral",
						href: `${crmSync.instanceUrl}/${crmSync.crmAccountId}`,
					},
					{
						label: t("Call logged"),
						status: "neutral",
						href: `${crmSync.instanceUrl}/${crmSync.crmCallLogTaskId}`,
					},
					{
						label: t("Follow-up task created"),
						status: "neutral",
						href: `${crmSync.instanceUrl}/${crmSync.crmFollowUpTaskId}`,
					},
				],
			}

			return {
				label: t("Call synced with CRM"),
				status: "success",
			}
		}

		const errorName = crmSync.errorName
		if (errorName === "CrmIntegrationDisabledForUserError") {
			/**
			 * happens when the assigned user has its CRM integration disabled
			 * for example, Intescia CSM
			 * When that's the case, we don't want to show the error message
			 */
			return null
		}

		const errorMessageTranslationKey = crmSyncErrorMessageTranslationKeyByErrorName[errorName]
		return {
			label: t("CRM sync failed"),
			status: "error",
			children: errorMessageTranslationKey
				? [
						{
							label: t(errorMessageTranslationKey),
							status: "error",
						},
				  ]
				: undefined,
		}
	}

	public getUnprocessableReasonString(t: TFunction): string {
		const reason = this._props.unprocessableReason
		if (!reason) {
			throw new Error("Call is processable")
		}
		switch (reason.code) {
			case HttpPresentedUnprocessableReasonCode.EmptyTranscription:
				return t("Nobody spoke during the call")

			case HttpPresentedUnprocessableReasonCode.LanguageNotSupported:
				return t("Language is not supported ({{languageCode}})", {
					languageCode: reason.languageCode,
				})

			default:
				assertNever(reason, true) as unknown
				return t("Call cannot be processed")
		}
	}

	private makeTimelineStatus(condition: unknown) {
		if (!this.isProcessable) {
			return "error"
		}
		return condition ? "success" : "pending"
	}

	public get isProcessable(): boolean {
		return !this._props.unprocessableReason
	}

	public isShortCall(): boolean {
		return Boolean(this.props.durationSec && this.props.durationSec < 60 * 15) && !this.isDemoCall() // 15 mins
	}

	public hasLeadKnowledge(): boolean {
		return Boolean(
			this.props.leadKnowledge && !areAllPropertiesNullishOrEmpty(omit(this.props.leadKnowledge, "version")),
		)
	}

	public isDemoCall() {
		return this.props.id === DEMO_CALL_ID
	}
}

const crmSyncErrorMessageTranslationKeyByErrorName: Record<string, TranslationKey> = {
	CallWithoutExternalAttendeeError: "The call had no external attendee.",
	NoMatchingCrmAccountError: "No matching CRM account found.",
	NoMatchingCrmContactError: "No matching CRM contact found.",
	NoMatchingCrmUserError: "The assigned user has no matching CRM user.",
	NoAssignedUserError: "The call has no assigned user.",
	NoCrmIntegrationError: "The workspace has no CRM integration set.",
}
