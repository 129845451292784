import { useEffect, useState } from "react"

class Semaphore {
	private count: number
	private queue: (() => void)[]

	constructor(initialCount: number) {
		this.count = initialCount
		this.queue = []
	}

	acquire = (): Promise<void> => {
		if (this.count > 0) {
			this.count--
			return Promise.resolve()
		} else {
			return new Promise((resolve) => {
				this.queue.push(resolve)
			})
		}
	}

	release = (): void => {
		if (this.queue.length > 0) {
			const next = this.queue.shift()
			if (next) {
				next()
			}
		} else {
			this.count++
		}
	}
}

type Listener<T> = (newValue: T) => void

export class CacheManager {
	private static instance: CacheManager
	private cacheMap: Map<string, unknown>
	private semaphores: Record<string, Semaphore>
	private listeners: Map<string, Listener<unknown>[]>

	private constructor() {
		this.cacheMap = new Map()
		this.semaphores = {}
		this.listeners = new Map()
	}

	public static getInstance(): CacheManager {
		if (!CacheManager.instance) {
			CacheManager.instance = new CacheManager()
		}
		return CacheManager.instance
	}

	public async getOrQuery<T>(
		unique_id: string,
		provider: (unique_id: string) => Promise<T>,
		predicate: (value: T) => boolean,
	): Promise<T> {
		// Ensure a semaphore exists for the unique_id
		if (!this.semaphores[unique_id]) {
			this.semaphores[unique_id] = new Semaphore(1)
		}

		// Acquire the semaphore
		await this.semaphores[unique_id].acquire()

		try {
			const cachedValue = this.cacheMap.get(unique_id) as T | undefined

			// Check if the cache is valid
			if (cachedValue && predicate(cachedValue)) {
				return cachedValue
			}

			// Fetch new data if not cached or invalid
			const newData = await provider(unique_id)

			// Update the cache
			this.cacheMap.set(unique_id, newData)

			// Notify listeners
			this.notifyListeners(unique_id, newData)

			return newData
		} finally {
			// Release the semaphore
			this.semaphores[unique_id].release()
		}
	}

	public async refreshFromQuery<T>(unique_id: string, provider: (unique_id: string) => Promise<T>): Promise<T> {
		return this.getOrQuery(unique_id, provider, (_) => false)
	}

	public subscribe<T>(unique_id: string, listener: Listener<T>): void {
		if (!this.listeners.has(unique_id)) {
			this.listeners.set(unique_id, [])
		}
		;(this.listeners.get(unique_id) ?? []).push(listener as Listener<unknown>)
	}

	public unsubscribe<T>(unique_id: string, listener: Listener<T>): void {
		const listeners = this.listeners.get(unique_id)
		if (listeners) {
			this.listeners.set(
				unique_id,
				listeners.filter((l) => l !== listener),
			)
		}
	}

	private notifyListeners<T>(unique_id: string, newValue: T): void {
		const listeners = this.listeners.get(unique_id)
		if (listeners) {
			listeners.forEach((listener) => listener(newValue))
		}
	}
}

// React Hook
export function useFromCacheOrQuery<T>(
	unique_id: string,
	provider: (unique_id: string) => Promise<T>,
	predicate: (value: T) => boolean,
): T | undefined {
	const [value, setValue] = useState<T | undefined>(undefined)
	const cacheManager = CacheManager.getInstance()

	useEffect(() => {
		const listener = (newValue: T) => {
			setValue(newValue)
		}

		cacheManager.subscribe(unique_id, listener)
		;(async () => {
			const data = await cacheManager.getOrQuery(unique_id, provider, predicate)
			setValue(data)
		})()

		return () => {
			cacheManager.unsubscribe(unique_id, listener)
		}
	}, [unique_id, provider, predicate, cacheManager])

	return value
}

// Example usage in a React component:
// const data = useFromCacheOrQuery("id", fetchDataProvider, isValid);
