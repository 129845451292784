import type { Call } from "../../../core/domain/Call.entity"
import { numberOfHeadingTrue } from "../iterutils/dichotomy"
import { IntSpan } from "../iterutils/iterutils"
type TranscriptionWord = {
	startsAtMs: number
	endsAtMs: number
	text: string
}

type TranscriptionTurnWithWords = {
	attendeeLabel: string
	words: TranscriptionWord[]
	startTimeSec: number
	endTimeSec: number
	content: string
}

type DisplayedTurnSegmentText = {
	type: "text"
	text: string
}

type DisplayedTurnSegmentSkip = {
	type: "skip"
}

type DataOriginQuote = {
	quoted_text: string
	timespan: IntSpan
}

export type IDisplayedTurnSegment = DisplayedTurnSegmentText | DisplayedTurnSegmentSkip

export type DisplayedTurn = {
	turnIndex: number
	segments: IDisplayedTurnSegment[]
	attendeeLabel: string
	timeSpan: IntSpan
}

export type DisplayedCall = {
	id: string
	name: string
	time: Date
	displayedTurns: DisplayedTurn[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getInTurnQuoteDisplay(index: number, turn: TranscriptionTurnWithWords, timeSpan: IntSpan): DisplayedTurn {
	const first_word_index = numberOfHeadingTrue(turn.words, (w) => w.endsAtMs < timeSpan.start)
	const last_word_index = numberOfHeadingTrue(turn.words, (w) => w.startsAtMs > timeSpan.end)
	const segments: IDisplayedTurnSegment[] = []
	if (first_word_index > 0) {
		segments.push({ type: "skip" })
	}
	if (first_word_index < last_word_index) {
		let accumulatedText = ""
		for (let i = first_word_index; i < last_word_index; i++) {
			accumulatedText += turn.words[i].text
		}
		segments.push({ type: "text", text: accumulatedText })
	}
	return { turnIndex: index, segments: segments, attendeeLabel: turn.attendeeLabel, timeSpan: timeSpan }
}

export function quoteDisplayFromTurns(
	turns: TranscriptionTurnWithWords[],
	quoteToDisplay: DataOriginQuote,
): DisplayedTurn[] {
	const first_turn_index = numberOfHeadingTrue(turns, (t) => t.endTimeSec * 1000 < quoteToDisplay.timespan.start)
	const last_turn_index = numberOfHeadingTrue(turns, (t) => t.startTimeSec * 1000 < quoteToDisplay.timespan.end)
	const displayedTurns: DisplayedTurn[] = []

	if (first_turn_index === last_turn_index - 1) {
		const turn = turns[first_turn_index]
		const segments: IDisplayedTurnSegment[] = []
		const foundPos = turn.content.indexOf(quoteToDisplay.quoted_text)
		if (foundPos >= 0) {
			if (foundPos > 0) {
				segments.push({ type: "skip" })
			}
			segments.push({ type: "text", text: quoteToDisplay.quoted_text })
			if (foundPos + quoteToDisplay.quoted_text.length < turn.content.length) {
				segments.push({ type: "skip" })
			}
		} else {
			// should not really happen
			segments.push({ type: "skip" })
			segments.push({ type: "text", text: quoteToDisplay.quoted_text })
			segments.push({ type: "skip" })
		}
		displayedTurns.push({
			turnIndex: first_turn_index,
			segments: segments,
			attendeeLabel: turn.attendeeLabel,
			timeSpan: quoteToDisplay.timespan,
		})
	} else {
		for (let i = first_turn_index; i < last_turn_index; i++) {
			const turn = turns[i]
			const ispan: IntSpan = new IntSpan(turn.startTimeSec * 1000, turn.endTimeSec * 1000)
			displayedTurns.push({
				turnIndex: i,
				segments: [{ type: "text", text: turn.content }],
				attendeeLabel: turn.attendeeLabel,
				timeSpan: ispan,
			})
		}
	}

	return displayedTurns
}

export function quoteDisplayFromCall(call: Call, quoteToDisplay: DataOriginQuote): DisplayedCall {
	const turns = call.props.transcription?.turns
	let displayedTurns: DisplayedTurn[] = []
	if (turns) {
		const v = turns as unknown
		displayedTurns = quoteDisplayFromTurns(v as TranscriptionTurnWithWords[], quoteToDisplay)
	}

	return { id: call.props.id, displayedTurns: displayedTurns, name: call.props.name, time: call.props.createdAt }
}
