import React from "react"
import { toast } from "react-toastify"

import { Button } from "../../../components/design-system/Button.component"
import { Input } from "../../../components/design-system/Input"
import { Modal } from "../../../components/design-system/Modal.component"
import { AdminLayout } from "../../../components/shared/AdminLayout/AdminLayout.component"
import { useDependencies } from "../../../contexts/dependencies.context"

// Define the expected response structure
type GenericCallbackResponse = {
	error?: string
}

export function BackendManagePage() {
	const { platformAdminGateway } = useDependencies()
	const [isRetryBotDownloadModalOpen, setIsRetryBotDownloadModalOpen] = React.useState(false)
	const [botId, setBotId] = React.useState("")
	const [isDisableUserModalOpen, setIsDisableUserModalOpen] = React.useState(false)
	const [userId, setUserId] = React.useState("")

	const handleRetryBotDownload = React.useCallback(async () => {
		try {
			const response = (await platformAdminGateway.genericCallback({
				function: "retry_bot_download",
				botId: botId,
			})) as GenericCallbackResponse

			if (response.error) {
				toast(response.error, {
					type: "error",
				})
			} else {
				toast("Bot download retry initiated", {
					type: "success",
				})
				setIsRetryBotDownloadModalOpen(false)
			}
		} catch (e) {
			toast("Failed to retry bot download", {
				type: "error",
			})
		}
	}, [botId, platformAdminGateway])

	const handleDisableUser = React.useCallback(async () => {
		try {
			const response = (await platformAdminGateway.genericCallback({
				function: "disable_user",
				userId: userId,
			})) as GenericCallbackResponse

			if (response.error) {
				toast(response.error, {
					type: "error",
				})
			} else {
				toast("User disabled successfully", {
					type: "success",
				})
				setIsDisableUserModalOpen(false)
			}
		} catch (e) {
			toast("Failed to disable user", {
				type: "error",
			})
		}
	}, [userId, platformAdminGateway])

	return (
		<AdminLayout origin="backend-manage" pageName="Back office: Backend Management">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold text-gray-900">Backend Management</h1>
						<p className="mt-2 text-sm text-gray-700">
							Manage various backend operations for the back office.
						</p>
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<Button
							eventName="Retry bot download clicked"
							variant="primary"
							onClick={() => setIsRetryBotDownloadModalOpen(true)}
						>
							Retry Bot Download
						</Button>
						<Button
							eventName="Disable user clicked"
							variant="primary"
							onClick={() => setIsDisableUserModalOpen(true)}
							className="ml-4"
						>
							Disable User
						</Button>
					</div>
				</div>
			</div>

			<Modal open={isRetryBotDownloadModalOpen} onClose={() => setIsRetryBotDownloadModalOpen(false)}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						handleRetryBotDownload()
					}}
				>
					<Input.Group>
						<Input.Label>Bot ID</Input.Label>
						<Input.Input
							type="text"
							placeholder="Enter Bot ID"
							value={botId}
							onChange={(e) => setBotId(e.target.value)}
						/>
					</Input.Group>
					<Button className="mt-4" eventName="Retry bot download submitted" variant="primary" type="submit">
						Submit
					</Button>
				</form>
			</Modal>

			<Modal open={isDisableUserModalOpen} onClose={() => setIsDisableUserModalOpen(false)}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						handleDisableUser()
					}}
				>
					<Input.Group>
						<Input.Label>User ID</Input.Label>
						<Input.Input
							type="text"
							placeholder="Enter User ID"
							value={userId}
							onChange={(e) => setUserId(e.target.value)}
						/>
					</Input.Group>
					<Button className="mt-4" eventName="Disable user submitted" variant="primary" type="submit">
						Submit
					</Button>
				</form>
			</Modal>
		</AdminLayout>
	)
}
