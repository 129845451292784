export function numberOfHeadingTrue<T>(list: T[], predicate: (item: T) => boolean): number {
	let left = 0
	let right = list.length

	while (left < right) {
		const mid = Math.floor((left + right) / 2)
		if (predicate(list[mid])) {
			// Move to the right half if the predicate is true
			left = mid + 1
		} else {
			// Move to the left half if the predicate is false
			right = mid
		}
	}

	return left // The first index where predicate evaluates to false
}
